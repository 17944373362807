@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap');

body {
  margin: 0;
  font-family: 'Rubik', sans-serif !important;
}

.sectionPadding {
  padding: 80px 0px 0px;
}

a {
  text-decoration: none !important;
  font-family: 'Rubik', sans-serif;
  cursor: pointer;
}

.imagingText {
  text-align: justify;
  font-size: 17px;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

.spanPharmacy {
  margin-left: 5px;
  font-size: 16px;
  color: #07ccec;
}

a:focus-visible {
  outline: none;
}

.mobileNav {
  display: none;
}

.mobileNav {
  background: #122b55;
  padding: 10px 15px;
}

.mobileNavTop {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  color: #fff;
  border-bottom: 1px solid rgb(255 255 255 / 27%);
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.mobileNavTop p {
  margin-bottom: 0px;
}

.mobileNavTop a {
  color: #07ccec;
  font-size: 15px;
}

.mobileNavInnerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.offcanvas {
  max-width: 85% !important;
  border-left: 4px solid #07ccec !important;
}

.offcanvas-header button.btn-close {
  background: #122b55 url(./Assets/images/close-btn.png);
  background-size: 35%;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  opacity: 1;
}

.offcanvas-header {
  background: #07ccec;
  padding: 20px 15px !important;
}

.offcanvas-header .offcanvas-title img {
  max-width: 200px;
}

.offcanvas-body .bg-body-tertiary {
  background: #fff !important;
}

.offcanvas-body .bg-body-tertiary .navbar-nav,
.offcanvas-body .bg-body-tertiary .nav-item {
  width: 100%;
}

.offcanvas-body .bg-body-tertiary .nav-link {
  width: 100%;
  border-bottom: 1px solid #dbdbdb;
  color: #00416f;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.offcanvas-body .dropdown-toggle::after {
  position: absolute;
  right: 0;
  top: 10px;
}

.offcanvas-body .navbar-nav .dropdown-menu {
  border: none;
  padding-left: 0px;
  font-size: 14px;
}

.offcanvas-body .navbar-nav .dropdown-menu .dropdown-item {
  color: #00416f;
  padding: 6px 10px;
  text-wrap: wrap;
}


.logo {
  max-width: 200px;
  cursor: pointer;
}

.mobile-toggle-icon {
  text-align: right;
}

.mobile-toggle-icon img {
  width: 30px;
}

.headerSec {
  position: absolute;
  top: 0;
  z-index: 9;
  left: 0;
  right: 0;
  width: 100%;
}

.topBar .nav {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.head-top-links {
  display: flex;
  align-items: center;
  gap: 10px;
}

.head-top-links a {
  color: #fff;
  text-decoration: none;
  display: flex;
  gap: 10px;
  font-weight: 500;
  font-size: 16px;
}

.head-top-links a div p {
  margin: 0;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}

.logoBox {
  padding-top: 15px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding: 0;
  margin: 0;
}

.topBar {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px -10px 20px rgba(0, 54, 110, .06);
  background: #fff;
  padding: 10px 15px;
}

.topBar .bg-body-tertiary {
  background-color: transparent !important;
}

.topBar .navbar-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.topBar .navbar-nav a {
  color: #00416f;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  /* text-transform: capitalize; */
  padding: 0;
}

.topBar .navbar-nav a:hover {
  color: #007b90;
}

.topBar .dropdown-menu a {
  padding: 7px 10px !important;
  font-size: 13px !important;
  border-bottom: 1px solid #ebebeb;
  min-width: 250px;
  display: inline-block;
  text-wrap: initial;
}

.topBar .nav-item .dropdown-menu {
  padding: 0;
}

.topBar .dropdown-menu a:last-child {
  border-bottom: none;
}

.topBar .dropdown-menu.show {
  margin-top: 19px;
  border: 1px solid #f3f3f3;
  border-radius: 0px;
  box-shadow: 0 3px 16px -11px rgba(0, 0, 0, .46);
}

.dropdown-menu.show .dropdown-item:hover {
  background: #08a2bb;
  color: #fff;
}

.dropdown-submenu-container {
  position: relative;
}

.dropdown-submenu-container a.dropdown-submenu::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: 0.8em;
}

.dropdown-menu-right .dropdown-submenu-container a.dropdown-submenu::after {
  transform: rotate(90deg);
  position: absolute;
  left: 0px;
  right: unset;
  top: 0.8em;
}

.dropdown-menu-end .dropdown-submenu-container a.dropdown-submenu::after {
  transform: rotate(90deg);
  position: absolute;
  left: 0px;
  right: unset;
  top: 0.8em;
}

.dropdown-submenu-container .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 1px;
  margin-right: auto;
}

.dropdown-menu-right .dropdown-submenu-container .dropdown-menu {
  left: auto;
  right: 100%;
  margin-left: auto;
  margin-right: 1px;
}

.dropdown-menu-end .dropdown-submenu-container .dropdown-menu {
  left: auto;
  right: 100%;
  margin-left: auto;
  margin-right: 1px;
}

.languageDropDown {
  font-family: 'Rubik', sans-serif;
  color: #fff;
  font-weight: 800;
  border: none;
  outline: none;
  background: transparent;
}

.languageDropDown option {
  color: #00416f;
}

.homeBannerSection .carousel-item img {
  width: 100%;
  height: 710px;
}


.nicu_image_slider {
  max-height: 460px !important;
  width: 100% !important;
  object-fit: cover !important;
}

.centered-text {
  text-align: center !important;
}

.carousel-item .carousel-caption {
  padding: 30px;
  text-align: left;
  right: auto;
  left: 10%;
  bottom: auto;
  top: 15%;
  margin-top: 8%;
  max-width: 560px;
  background: rgb(0 0 0 / 30%);
  border-radius: 6px;
}

.carousel-item .carousel-caption h3 {
  font-family: 'Rubik', sans-serif;
  font-weight: 900;
  font-size: 2.2rem;
  text-shadow: 0px 1px 8px #1b1b1b;
}

/* .carousel-item .carousel-caption h3 span {
  color: #8aefff;
  text-decoration: underline;
} */

.carousel-item .carousel-caption h4 {
  font-family: 'Rubik', sans-serif;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 20px;
  margin: 15px 0px 5px;
  text-shadow: 0px 1px 8px #1b1b1b;
}

.carousel-item .carousel-caption p {
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
  font-size: 20px;
  margin: 5px 0px;
  text-shadow: 0px 1px 8px #1b1b1b;
}


.restaurent_page_banner {
  text-shadow: none !important;
  background: none !important;
  color: #b06400 !important;
}

.restaurent_page_banner_para {
  text-shadow: none !important;
  color: #000 !important;
  font-size: 24px !important;
  font-weight: 400 !important;
}

.carousel-item .prmoCaption {
  background: transparent;
}

.carousel-item .prmoSlideCaption3 h3 {
  color: #b06400;
  text-shadow: none;
}

.carousel-item .prmoSlideCaption3 p {
  text-shadow: none;
  color: #000;
  font-size: 24px;
  font-weight: 400;
}

.carousel-item .prmoSlideCaption4 {
  background: transparent;
}

.carousel-item .prmoSlideCaption4 h3 {
  color: #b06400;
  text-shadow: none;
}

.carousel-item .prmoSlideCaption4 p {
  text-shadow: none;
  color: #000;
  font-size: 24px;
  font-weight: 400;
}

.carousel-item .descPrice {
  text-transform: capitalize;
  font-weight: 400 !important;
  letter-spacing: 0.5px;
  text-decoration: underline;
}



.sliderBtn {
  display: inline-block;
  padding: 0 25px;
  margin-top: 20px;
  color: #114e79;
  line-height: 50px;
  min-height: 50px;
  min-width: 140px;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  background: #114e79;
  box-shadow: 0 6px 27px 0 rgb(17 78 121 / 40%);
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.holyPlace img {
  height: 350px;
  width: 100%;
  object-fit: cover;
}

/* Rectangle In */
.hvr-rectangle-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #114e79;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.hvr-rectangle-in:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #8aefff;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-rectangle-in:hover,
.hvr-rectangle-in:focus,
.hvr-rectangle-in:active {
  color: #fff;
}

.hvr-rectangle-in:hover:before,
.hvr-rectangle-in:focus:before,
.hvr-rectangle-in:active:before {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.service-thumb-container {
  display: flex;
  gap: 3px;
  flex-direction: column;
  max-width: 300px;
  position: absolute;
  right: 10%;
  top: 30%;
}

.service-col .thumbIcons {
  height: 65px;
}

.service-col {
  cursor: pointer;
  padding: 18px 20px;
  width: 100%;
  color: #00416f;
  position: relative;
  text-align: left;
  border-radius: 5px;
  background: #eafcff;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: start;
  overflow: hidden;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  -ms-transition: all .3s ease-out 0s;
  -o-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.serThumbTxt {
  flex-wrap: wrap;
}

.serThumbTxt h4 {
  width: 100%;
  margin-bottom: 3px;
  font-size: 1.1rem;
  font-family: 'Rubik', sans-serif;
}

.serThumbTxt a {
  text-decoration: none;
  color: #114e79;
  font-size: 14px;
}

.serThumbBg {
  position: absolute;
  width: 100px;
  right: 0;
  bottom: 0;
  opacity: 0.6;
}

/* Underline From Left */
.hvr-underline-from-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  overflow: hidden;
}

.hvr-underline-from-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #00416f;
  height: 2px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-underline-from-left:hover:before,
.hvr-underline-from-left:focus:before,
.hvr-underline-from-left:active:before {
  right: 0;
}

.homeBannerSection {
  position: relative;
}

.fixedIcons {
  position: fixed;
  right: 2px;
  top: 30%;
  z-index: 99;
}

.fixedIcons a {
  display: block;
  background: #07ccec;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0px -5px 15px rgb(13 202 240 / 40%);
  border-radius: 50px;
  border: 1px solid #e2e2e2;
  text-align: center;
}

.fixedIcons a:hover {
  background: #122b55;
}

.fixedIcons a img {
  height: 26px;
}

.homeAboutSection {
  position: relative;
}

.homeAboutSection .row {
  align-items: center;
}

.homeAboutTxt h4 {
  margin-bottom: 15px;
  font-family: 'Rubik', sans-serif;
  font-weight: 900;
  font-size: 2rem;
  color: #07ccec;
}

.homeAboutTxt h4 span {
  color: #00416f;
}

.homeAboutTxt p {
  text-align: justify;
  line-height: 26px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}

.hospitalImg img {
  border-radius: 30px;
}

.home-about-shape {
  position: absolute;
  width: 45%;
  left: 0;
  top: 5%;
  z-index: -1;
}

.homeServiceSection {
  background: url('./Assets/images/bg-shape-2.jpg');
  background-size: 100% 100%;
  background-position: left bottom;
}

.facilityBox {
  padding: 30px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 30px 0 rgba(0, 20, 9, 0.1);
  -moz-box-shadow: 0px 0px 30px 0 rgba(0, 20, 9, 0.1);
  box-shadow: 0px 0px 30px 0 rgba(0, 20, 9, 0.1);
  border-radius: 3px;
  font-family: 'Rubik', sans-serif;
  min-height: 238px;
  margin-bottom: 30px;
}

.facilityInnerBox {
  min-height: 110px;
}

.facilityBox h3 {
  color: #27477d;
  font-family: 'Rubik', sans-serif;
  font-size: 1.5rem;
  font-weight: 800;
}

.facilityBox h3 span {
  color: #07ccec;
}

.facilityBox h4 {
  font-size: 1.2rem;
}

.contactInfo div {
  margin-bottom: 20px;
}

.contactInfo span {
  font-family: 'Rubik', sans-serif;
  display: table-cell;
  padding-right: 10px;
  vertical-align: middle;
  color: #fff;
}

.contactInfo span img {
  width: 23px;
}

.contactInfo span a {
  display: block;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  word-break: break-all;
}

.contactInfo span a:hover {
  color: #07ccec;
}


/* =========== Inner Page Style ============ */

.innerPageHeader {
  background-color: #122a53;
  padding: 100px 0px;
}

.innerPageHeader_for_Imaging {
  background-color: #122a53;
  padding: 100px 0px;
}

.emergenciPageBanner {
  background: url(./Assets/images/slider01.jpg);
  background-size: cover;
  background-position: top center;
  padding-top: 17%;
  padding-bottom: 17%;
  /* opacity: 0.5; */
}

.imageTransparent {
  background: rgb(228 251 255);
  padding: 30px !important;
  border-radius: 15px !important;
  color: #00416f;
}

.imageTransparent ul{
  padding: 0;
}

ul li {
  list-style-type: disc !important
}

.imageTransparent-sinister {
  background: rgba(0, 0, 0, .22);
  border-radius: 15px !important;
  color: #00416f !important;
  padding: 30px !important;
  background-color: #e4fbff;
}

.emergenciPageBannerContnt {
  padding: 20px;
  max-width: 530px;
  border-radius: 15px;
  background: rgb(18 42 83 / 80%);
}

.emergenciPageBannerContnt h2 {
  display: block;
  margin-bottom: 10px;
  font-family: 'Rubik', sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2.3rem;
  color: #fff;
  letter-spacing: 1px;
  text-shadow: 0px 1px 8px rgb(0 0 0 / 36%);
}

.emergencNote {
  color: #fff;
  font-weight: 500;
  font-size: 1.18rem;
  font-family: 'Rubik', sans-serif;
  text-shadow: 0px 1px 3px rgb(27 27 27 / 50%);
}

.emergencSafety {
  margin-bottom: 20px;
}

.emergencSafety h5 {
  color: #122a53;
  font-weight: 900;
  font-size: 1.3rem;
  line-height: 28px;
  font-family: 'Rubik', sans-serif;
}

.emergency_page_span {
  font-size: 1.0rem;
  line-height: 26px;
  font-family: 'Rubik', sans-serif;
}

.emergencNote_for {
  color: #fff;
  font-weight: 500;
  font-size: 1.15rem;
  text-shadow: 0px 1px 3px rgb(27 27 27 / 50%);
}

.second_li {
  font-family: 'Rubik', sans-serif;
}

.emergencSafety li {
  font-size: 1.0rem;
  line-height: 26px;
  font-family: 'Rubik', sans-serif;
}

.moreQuestion h4 {
  color: #122a53;
  font-weight: 900;
  font-size: 1rem;
  line-height: 26px;
  display: block;
  margin-bottom: 1%;
  margin-top: 2%;
  font-family: 'Rubik', sans-serif;
}

.emergenciPageBannerContnt h2 span {
  color: #8aefff;
  text-decoration: underline;
}

.emergenciPageBannerContnt h4 {
  /* margin-bottom: 15px; */
  font-family: 'Rubik', sans-serif;
  font-size: 1.6rem;
  color: #fff;
  text-shadow: 0px 1px 3px rgb(27 27 27 / 70%);
}

.emergenciPageBannerContnt a {
  font-family: 'Rubik', sans-serif;
  font-size: 1.6rem;
  color: #fff;
  text-shadow: 0px 1px 3px rgb(27 27 27 / 50%);
}

.emergencPageContant {
  position: relative;
}

.emergencPageContantImage {
  border-radius: 20px;
}

.emergencPageContant h1 {
  font-family: 'Rubik', sans-serif;
  font-size: 2rem;
  line-height: 44px;
  margin-bottom: 10px;
  font-weight: 900;
  color: #00416f;
}

.emergencPageContant h2 {
  font-family: 'Rubik', sans-serif;
  font-size: 1.4rem;
  line-height: 32px;
}

.emergenciPageBannerContnt img {
  width: 50px;
  margin-right: 5px;
  vertical-align: text-bottom;
  animation: horizontal-shaking 0.45s infinite;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0)
  }

  25% {
    transform: translateX(3px)
  }

  50% {
    transform: translateX(-3px)
  }

  75% {
    transform: translateX(3px)
  }

  100% {
    transform: translateX(0)
  }
}

.emergency-page-shape {
  position: absolute;
  width: 45%;
  right: 0;
  top: 0%;
  z-index: -1;
  opacity: 0.8;
}

.moreQuestion a {
  cursor: pointer;
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #4f63ff !important;
  text-decoration: underline !important;
  margin-bottom: 8px;
}

.moreQuestion a span {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  background: #4f63ff;
  text-align: center;
  border-radius: 30px;
  line-height: 24px;
  font-size: 11px;
  color: #fff;
}

.downloadMenu a:hover .iconsLink,
.moreQuestion a:hover .iconsLink,
.innerURL li:hover .iconsLink {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.sinisterPageBanner {
  background: url(./Assets/images/sinister-bg.jpg);
  background-size: cover;
  padding: 100px 0px;
  position: relative;
}

.sinisterPageBanner::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #00acc8;
  opacity: .8;
}

.pageTitle {
  position: relative;
  text-align: center;
}

.pageTitle h2 {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  text-transform: capitalize;
  color: #fff;
  font-size: 2.3rem;
  font-weight: 900;
  text-shadow: 0px 1px 5px rgb(0 0 0 / 35%);
}

.breadcrumbs {
  padding: 13px 0px;
  background: #e4fbff;
  margin-bottom: 30px;
}

.breadcrumbs h2 {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  text-transform: capitalize;
  font-size: 1.5rem;
  color: #122a53;
}

.bread-list {
  padding: 0;
  margin: 0;
}

.bread-list li {
  font-family: 'Rubik', sans-serif;
  list-style: none;
  display: inline-block;
  font-size: 16px;
  color: #122a53;
}

.bread-list li i {
  margin: 0px 10px;
}

.hemodynamics_imageSlider {
  height: 400px !important;
  width: 700px !important
}

.not-apply-justify {
  text-align: left !important;
}

.sinisterTxt p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 10px;
  text-align: justify;
  font-family: 'Rubik', sans-serif;
}

.sinisterTxt p a {
  color: #00416f !important;
}

.prayer_images {
  width: 45%;
}

.sinisterTxt ul li {
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  line-height: 28px;
  list-style: decimal-leading-zero;
  list-style-position: inside;
}

.hospitalMapPageContant h4 {
  font-family: 'Rubik', sans-serif;
  font-weight: 800;
  margin-bottom: 30px;
  font-size: 2rem;
}

.addressInfoBox {
  padding: 40px;
  margin-bottom: 30px;
  min-height: 556px;
  background: #122a53 url(../src/Assets/images/diamap.png);
  border-radius: 15px;
  color: #fff;
  position: relative;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  -ms-transition: all .3s ease-out 0s;
  -o-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.addressInfoBox:hover {
  transform: translateY(-5px);
}

.addressInfoBox h3,
.contactInfoBox h3 {
  font-family: 'Rubik', sans-serif;
  font-size: 1.4rem;
  position: relative;
  margin-bottom: 25px;
}

.addressInfoBox h3::after,
.contactInfoBox h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 20%;
  height: 1px;
  background: #fff;
}

.contactInfoBox {
  font-family: 'Rubik', sans-serif;
  background: #122a53;
  padding: 30px;
  border-radius: 15px;
  min-height: 200px;
  color: #fff;
}

.addressInfoBox a,
.contactInfoBox a,
.addressInfoBox b {
  color: #00acc8;
}

.addressInfoBox ul {
  padding: 0;
  margin: 0;
  margin-left: 25px;
}

.addressInfoBox li {
  font-family: 'Rubik', sans-serif;
  list-style: decimal-leading-zero;
  margin-bottom: 15px;
}

.addressInfoBox li:last-child {
  margin-bottom: 0;
}

.loadingPage {
  background-color: #00416f;
  width: 100vw;
  height: 100vh;
  text-align: center;
  position: relative;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingPage img {
  width: 400px;
}

.imagingPageBanner {
  background: url(./Assets/images/imaging_banner.jpg);
  /* background: url(./Assets/images/imaging-bg.png); */
  padding: 10% 0;
  background-size: cover;
  background-position: bottom;
  min-height: 550px;
}

.imagingPageBannerContnt {
  padding: 20px;
  background: hsl(204.81deg 83.84% 18.07% / 30%);
  border-radius: 10px;
}

.imagingPageBannerContnt h4 {
  line-height: 36px;
  font-size: 1.5rem;
}

.visiter_para {
  text-align: justify;
}

.imagingStudies {
  margin-bottom: 40px;
}

.imagingStudies h4 {
  text-align: center;
  line-height: 36px;
  color: #00416f;
  font-weight: 600;
}

.innerURL ul {
  padding: 0;
  margin: 0;
  margin-top: 15px;
}

.innerURL li {
  font-size: 16px;
  line-height: 26px;
  margin-top: 6px;
  list-style: decimal-leading-zero;
  list-style-position: inside;
  color: #4f63ff;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
}

.moreQuestion p {
  margin-bottom: 8px;
  font-size: 16px;
}

.pharmacy-text-read-more {
  text-align: justify;
}

.pageDtlsTitle {
  text-align: center;
  margin-bottom: 30px;
}

.pageDtlsTitle_heading {
  margin-bottom: 30px;
  color: #122a53;
}

.certificate {
  /* text-align: center; */
  margin-bottom: 30px;
}


.our_history {
  color: #00416f !important;
  font-family: 'Rubik', sans-serif;
}


.certificate h2 {
  font-family: 'Rubik', sans-serif;
  font-size: 1.6rem;
  line-height: 40px;
  margin-bottom: 5px;
  font-weight: 900;
  text-align: center;
  color: #00416f !important;
}

.certificate {
  font-family: 'Rubik', sans-serif;
  font-size: 1.1rem;
}

.pageDtlsTitle h2 {
  font-family: 'Rubik', sans-serif;
  font-size: 1.6rem;
  line-height: 40px;
  margin-bottom: 5px;
  font-weight: 900;
  color: #00416f !important;
}

.pageDtlsTitle p {
  font-family: 'Rubik', sans-serif;
  font-size: 1.1rem;
}

.resonicaServiceList,
.imageGallery {
  width: 50%;
}

.imageGallery {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.resonicaThumbImg {
  padding-left: 2% !important;
}



.resonicaThumbImg img {
  border-radius: 15px;
}

.pageImgGaller {
  margin-top: 30px;
}

.pageImgGaller img {
  width: 20%;
  padding: 5px;
  height: 230px;
  object-fit: cover;
  border-radius: 20px;
}

.visitorsContant {
  font-family: 'Rubik', sans-serif;
}

.visitorsContant li {
  list-style: decimal-leading-zero;
  margin-bottom: 15px;
  line-height: 26px;
  text-align: justify;
}

.visitorsContant li p {
  margin-bottom: 0px;
}

.hospitalParkingPageContant {
  font-family: 'Rubik', sans-serif;
  text-align: justify;
}

.parkingWrapper h5 {
  line-height: 28px;
}

.hospitalParkingPageContant p {
  margin-bottom: 10px;
  line-height: 28px;
}

.parkingRulsTxt {
  padding: 0;
  margin: 0;
  margin-left: 30px;
}

.parkingRulsTxt li {
  list-style: decimal-leading-zero;
  margin-bottom: 10px;
  line-height: 24px;
}

.parkingRulsTxt li p {
  text-align: justify;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 0px;
}

.parkingWrapper {
  position: relative;
  text-align: justify;
}

.parkingWrapper img {
  left: -15%;
  width: 70%;
}

.ecoFriendlyPageTxt p {
  font-family: 'Rubik', sans-serif;
  margin-bottom: 10px;
}

.ecoFriendlyPageTxt ul li {
  line-height: 28px;
}

.ecoFriendlyPageTxt h4 {
  margin-bottom: 10px;
  line-height: 30px;
}

.homeBannerSection .slick-dots li button:before {
  font-size: 15px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  opacity: 1;
  color: #cff8ff;
}

.homeBannerSection .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #00acc8;
}

.img-radius {
  border-radius: 20px;
}

.table thead th {
  color: #fff;
  font-size: 14px;
  background: #00416f;
  text-transform: capitalize;
}

.ihnaloterapiaGallery img {
  height: 340px;
  width: 100%;
  object-fit: cover;
}

.starbuksDtlsWrapper {
  max-width: 520px;
  margin: 50px auto;
  padding: 30px;
  text-align: center;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 10px #ededed;
}

.starBuksInnerBox h4 {
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-weight: 900;
}

.starBuksInnerBox p {
  margin-bottom: 0;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  color: #555;
}

.starBuksInnerBox img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  object-fit: cover;
  margin-bottom: 15px;
}

.paymentIcons {
  width: 100%;
}

.paymentIconsforCard {
  width: 250%;
}


.iconsLinkForemail {
  height: 26px;
  width: 26px;
  margin-right: 5px;
  margin-bottom: 0px !important;
}

.iconsLinkForcall {
  height: 24px;
  width: 25px;
  margin-right: 5px;
  margin-bottom: 0px !important;
}

.iconsLinkForemailTower {
  height: 23px;
  width: 23px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 0px !important;
}

.iconsLinkForwhatsapp {
  height: 27px;
  width: 27px;
  /* margin-left: -4px; */
}

.restuarentsWrapper {
  background: #f4f1f0;
  margin-bottom: -30px;
  padding-bottom: 20px;
}

.resturentTitle {
  color: #000;
  font-weight: 600;
  font-size: 1.6rem !important;
  margin-bottom: 20px;
}

.img_bottom {
  margin-bottom: 24%;
}

.menu-item {
  background: #fff;
  border-radius: 20px;
  padding: 15px;
  border: 1px solid #ededed;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 30px;
}

.menu-item h4 {
  margin-bottom: 5px;
  font-size: 1.2rem;
  color: #000;
  text-transform: capitalize;
}

.menu-item p {
  margin: 0;
  font-weight: 400;
  color: #4b4b4b;
}

.foodImg {
  border-radius: 20px;
  height: 300px !important;
  width: 100%;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;
}

.downloadMenu {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
  font-family: 'Rubik', sans-serif;
}

.downloadMenu_restaurent {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
}

.downloadMenu a {
  color: #4f63ff;
  text-decoration: underline !important;
}

.promImg {
  object-fit: cover;
  height: 300px;
  width: 300px;
  border-radius: 50%;
  margin-bottom: 50px;
}

.restuarentsWrapper .carousel {
  margin-bottom: 50px;
}

.restuarentsWrapper .carousel-item img {
  height: auto;
  width: 100%;
}

.menuCategoryOpt {
  margin-bottom: 30px;
  box-shadow: 0px 0px 10px #cccc;
  border-radius: 15px;
}

.menuCategoryOpt .imgBox {
  cursor: pointer;
}

.menuCategoryOpt .imgBox img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
}

.menuCategoryOpt .imgBox h4 {
  color: #000;
  padding: 15px 0;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  background: #fff;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
  border-radius: 0px 0px 15px 15px;
}

.menuCategoryOpt .imgBox1 img {
  width: 100%;
  height: 450px;
  border-radius: 15px 15px 0px 0px;
}

.menuCategoryOpt .imgBox1 h4 {
  color: #000;
  padding: 15px 0;
  font-weight: 500;
  font-size: 25px;
  text-align: center;
  background: #fff;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
  border-radius: 0px 0px 15px 15px;
}

.menuCategoryOpt .imgBox1 p {
  color: #000;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  background: #fff;
  font-family: 'Rubik', sans-serif;
  border-radius: 0px 0px 15px 15px;
}

.resturentInnerPage {
  gap: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.resturentInnerPage div {
  margin-bottom: 30px;
}

.resturentInnerImg {
  width: 300px;
  height: 300px;
  border-radius: 100%;
  object-fit: cover;
  margin-bottom: 15px;
}

.resturentInnerPage h5 {
  text-align: center;
  font-family: 'Rubik', sans-serif;
}

.resturentBreakfastDiv {
  gap: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin-left: 160px;
}

.slider_image_home_margin {
  margin-top: 12% !important;
}

.remove_bullets {
  list-style: none !important
}

.anchor_tag {
  margin-top: -31px;
  padding: 5px;
}

.anchor_img {
  margin-left: -14px;
}

.resturentBreakfastP {
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.incSerOptRow {
  justify-content: center;
}

.insurancepageHeading {
  text-align: center;
  margin-bottom: 50px;
  font-family: 'Rubik', sans-serif;
}

.insurancepageHeading h4 {
  color: #004470;
  font-size: 2rem;
  font-weight: 800;
  text-transform: capitalize;
}

.insurance_not_capital_letter {
  text-transform: none !important;
}

.insurancepageHeading p {
  font-size: 18px;
  color: #2b4776;
}

.incSrvcBox {
  cursor: pointer;
  padding: 15px;
  min-height: 330px;
  margin-bottom: 30px;
  border-radius: 20px;
  text-align: center;
  background: #e4fbff;
  box-shadow: 0px -10px 15px #e4e6e0;
}

.incSrvcBox img {
  width: auto;
  height: 200px;
  margin-bottom: 15px;
}

.incSrvcBox h4 {
  color: #1d3c70;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.4rem;
  text-align: center;
  /* text-transform: capitalize; */
  font-family: 'Rubik', sans-serif;
}

.incPageContactInfo {
  margin-top: 30px !important;
}

.incsocialIcons .icons {
  color: #fff;
  padding: 5px;
  border-radius: 30px;
  background-color: #00416f;
  width: 35px;
  height: 35px;
  display: inline-block;
  text-align: center;
  line-height: 26px;
  margin-right: 5px;
}

.incsocialIcons a:last-child {
  margin-right: 0;
}

.incsocialIcons,
.moreInfor,
.whatsApp,
.whatsApp a,
.siteUrl {
  color: #004470;
  font-size: 17px;
  font-weight: 900;
  margin-bottom: 10px;
}

.incContactInfo .whatsApp {
  text-align: right;
}

.barCodeImg {
  width: 40%;
}

.directoryTable {
  font-size: 20px;
  font-weight: 600;
}

.dividerHr {
  width: 90%;
  height: 5px;
  margin: 15px 0;
  border-radius: 10px;
  background-color: #00416f;
}

.siteUrl {
  font-size: 26px;
}

.modal-dialog {
  max-width: 550px !important;
}

.modal-header {
  background-color: #07ccec;
}

.modal-title.h4 {
  font-weight: 800;
  font-size: 1.3rem;
  color: #122a53;
  font-family: 'Rubik', sans-serif;
}

.modal-body .form-control {
  font-family: 'Rubik', sans-serif;
  border-radius: 6px !important;
  width: 100% !important;
  height: 46px;
}

.modal-body .form-label {
  font-family: 'Rubik', sans-serif;
  margin-bottom: 0.2rem;
  color: #122a53;
  font-weight: 600;
  font-size: 14px;
}

.modal-body select {
  width: 100%;
  height: 37px;
  height: 46px;
  color: #212529;
  padding-left: 10px;
  border: 1px solid #ccc;
  border-radius: 6px !important;
  font-family: 'Rubik', sans-serif;
}

.sendBtn {
  border: none !important;
  color: #00416f !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.sendBtn {
  color: #fff !important;
  background-color: #00416f !important;
}

.call_image {
  background: #122a53;
  border-radius: 6px;
  text-align: center;
  height: 35px;
  padding: 5px;
  margin-right: 5px;
  vertical-align: text-bottom;
  animation: horizontal-shaking 0.45s infinite;
}

.parkingBigImg {
  width: 100%;
  height: 103% !important;
}

.contactDtlsBloodBank h4 {
  font-weight: 700;
  margin-bottom: 15px;
}

.contactDtlsBloodBank a {
  color: #00acc8;
  /* display: block; */
  margin-bottom: 6px;
}

.pathologySlider .carousel-item img {
  height: 400px !important;
  width: 100% !important;
  object-fit: cover;
  object-position: bottom;
}

.ourHistoryContant p {
  line-height: 26px;
  text-align: justify;
  font-family: 'Rubik', sans-serif;
}

.medicalTowerContant {
  text-align: justify;
  padding: 30px;
  max-width: 500px;
  margin: 30px auto;
  margin-right: 15px;
  border-radius: 10px;
  background-color: #fff;
  font-family: 'Rubik', sans-serif;
  box-shadow: 0px 0px 8px rgb(7 204 236 / 27%);
}

.medicalTowerContant p {
  margin: 0;
}

.medicalTowerContant p a {
  color: #00acc8;
}

.blueExtension {
  color: #0d6efd !important;
}

.prmoSliderTxt h3 {
  color: orange;
}

.promotions h1 {
  font-family: 'Rubik', sans-serif;
  text-align: center;
  font-size: 2.0rem;
  line-height: 40px;
  margin-bottom: 5px;
  font-weight: 900;
  color: rgb(18 42 83);
}

.promotions h4 {
  font-family: 'Rubik', sans-serif;
  text-align: center;
  font-size: 1.6rem;
  line-height: 40px;
  margin-bottom: 5px;
  font-weight: 900;
  padding-top: 10px;
  /* color: rgb(18 42 83); */
}

.promotionsDiv {
  margin: 20px;
  padding: 20px;
  width: auto;
  border-radius: 8px;
  background-color: #fff;
  font-family: 'Rubik', sans-serif;
  box-shadow: 0px 0px 8px rgb(7 204 236 / 27%);
}

.promotionsDiv ul {
  padding: 0;
  margin-bottom: 10px;
}

.promotionsDiv ul li {
  list-style-position: inside;
  line-height: 30px;
}

.promotionsDiv p {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.promotionsDiv div {
  padding: 10px;

}

.promotionsDiv .promotion-content {
  display: flex;
  justify-content: center;
}

.promotionsDiv div h5 {
  margin-left: 15px;
}

/* ================== Footer =================== */

#footer {
  margin-top: 30px;
  padding: 70px 0px 40px 0px;
  background: url(./Assets/images/footer-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.footer-widget .footerLogo {
  width: 85%;
}

.footer-widget p {
  color: #fff;
  margin-bottom: 0;
  margin-top: 30px;
  font-family: 'Rubik', sans-serif;
  /* text-align: justify; */
}

.footer-social {
  margin-top: 20px;
}

.footer-social a {
  display: inline-block;
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  background-color: rgba(39, 71, 125, .28);
  color: #7f9ccd;
  margin-right: 10px;
  border-radius: 50%;
}

.footer-social a img {
  height: 23px;
}

.footer-social a:hover {
  background-color: #07ccec;
}

.footer-title {
  color: #fff;
  font-size: 23px;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 20px;
}

.footer-blog .blogImg {
  width: 60px;
  object-fit: cover;
  border-radius: 3px;
}

.footer-blog .blogCol {
  display: flex;
  gap: 8px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgb(255 255 255 / 6%);
}

.footer-blog .blogCol h4 {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}

.footer-blog .blogCol p {
  margin-top: 5px;
  font-size: 12px;
}


#menu-useful-links li {
  padding: 0;
  margin: 0;
  list-style-type: none !important;
}

#menu-useful-links li {
  list-style: none;
  line-height: 35px;
  width: 100%;
  float: left;
  border-bottom: 1px solid #ffffff3d;
}

#menu-useful-links li:last-child {
  border-bottom: none;
}

#menu-useful-links li a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
}

#menu-useful-links li a:hover {
  color: #07ccec;
}

.copyright-footer {
  color: #fff;
  padding-top: 20px;
  border-top: 1px solid #ffffff12;
  margin-top: 25px;
}

.copyright-footer .d-flex {
  align-items: center;
  justify-content: space-between;
}

.copyright-footer .d-flex a {
  margin-left: 15px;
}


.carousel-control-next,
.carousel-control-prev {
  width: 9% !important;
  opacity: 1 !important;
}

.laboratoryImageGallery img {
  height: 300px;
  object-fit: cover;
  width: 100%;
}

.allFacality img {
  height: 350px;
  width: 100%;
  object-fit: cover;
}

.nicuItem img {
  height: 400px;
  width: 100%;
}

.hospitalMapPageContant h4 span {
  color: #00acc8;
}

.hospitalMapPageContant .textDarkBlue {
  color: #00416f;
}

.InsuranceReportContant h4 {
  color: #00416f;
  font-weight: 600;
  font-family: 'Rubik', sans-serif;
}

.InsuranceReportContant a {
  color: #07ccec;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}

.InsurancePageContant {
  margin-top: -30px;
  padding-top: 50px;
  margin-bottom: -30px;
  padding-bottom: 50px;
  /* background-color: #98beff; */
  /* background: url(./Assets/images/pngtree-small.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
}

.UltrasoundGallery img {
  height: 550px !important;
  width: 100% !important;
  object-fit: cover;
  /* object-position: bottom; */
}

.cardiology_image {
  object-fit: cover;
}

.contraseStudiesBigImg {
  height: 710px !important;
  object-fit: cover;
  object-position: bottom;
  width: 100%;
}

.nursingBigImg {
  height: 700px !important;
  object-fit: cover;
  object-position: top;
  width: 100%;
}

.medicaltowerBigImg {
  height: 610px !important;
  object-fit: cover;
  object-position: bottom;
  width: 100%;
}

.privacypolicyHeading {
  text-align: center;
}

.contrasSmallImg {
  height: 115% !important;
}

.pratoryDIV {
  margin-left: 200px !important;
  margin-right: 200px !important;
}

.ortaryIMG {
  width: 4000px !important;
}

.iconsLink {
  width: 14px;
  top: -6px;
  position: relative;
  left: 3px;
  margin-top: 13px;
}

.DirectoryPageContant {
  margin-top: -30px;
  padding-top: 30px;
  margin-bottom: -30px;
  padding-bottom: 50px;
  background: url(./Assets/images/directory.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.small_image_intensive {
  height: 415px !important;
}

.emergency {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -2%;
  padding-top: 2%;
  margin-bottom: -2%;
  padding-bottom: 2%;
}

.raconica {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -2%;
  padding-top: 2%;
  margin-bottom: -2%;
  padding-bottom: 2%;
}

.tomography {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -2%;
  padding-top: 2%;
  margin-bottom: -2%;
  padding-bottom: 2%;
}

.contraseStudies {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -2%;
  padding-top: 2%;
  margin-bottom: -2%;
  padding-bottom: 2%;
}

.Xrays {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -2%;
  padding-top: 2%;
  margin-bottom: -2%;
  padding-bottom: 2%;
}


.Ultrasound {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -2%;
  padding-top: 2%;
  margin-bottom: -2%;
  padding-bottom: 2%;
}

.Cardiology {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -2%;
  padding-top: 2%;
  margin-bottom: -2%;
  padding-bottom: 2%;
}

.Neurology {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -2%;
  padding-top: 2%;
  margin-bottom: -2%;
  padding-bottom: 2%;
}

.promotionBack {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -2%;
  padding-top: 2%;
  margin-bottom: -2%;
  padding-bottom: 2%;
}

.pharmacyImg {
  height: 30px;
  margin-right: 5px;
}

.by_pass_surgery_image {
  width: 90%;
}

.pharmacyButton {
  border: 1px solid #0e03a8;
  border-radius: 12px;
  padding: 14px 10px;
  font-size: 13px;
  background: #0e03a8;
  font-weight: 600;
  color: #fff;
  margin-right: 8px;
}

.bg {
  margin-top: -2%;
  padding-top: 2%;
  margin-bottom: -2%;
  padding-bottom: 31%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.chatBotBtn {
  cursor: pointer;
  color: #fff;
  border: none;
  margin-top: 20px;
  padding: 8px 20px;
  border-radius: 8px;
  background: #0e03a8;
}

.reportSubHeading {
  padding-left: 35px;
}

.nutrition_images {
  height: 460px !important;
}

.physical_images {
  height: 370px !important;
  width: 100%;
}


/* ============== Media Query ============ */

@media (min-width: 1920px) and (max-width: 2560px) {
  .parkingBigImg {
    width: 100%;
    height: 100% !important;
  }
}

.siniter_medical {
  font-size: 20px !important;
  font-weight: 900 !important;
}

.resonica_images {
  object-fit: cover;
  width: 100%;
  height: 460px !important;
}

.clinical_images {
  height: 450px !important;
  width: 100%;
}

@media (max-width: 640px) {
  .homeBannerSection .carousel-item img {
    width: 100%;
    max-height: 212px;
    background-size: cover;
  }

  .medicaltowerBigImg {
    height: 610px !important;
    object-fit: cover;
    object-position: bottom;
    width: 100%;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .topBar .navbar-nav a {
    font-size: 14px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .navbar-nav {
    flex-direction: row !important;
  }

  .parkingBigImg {
    width: 100%;
    height: 103% !important;
  }

  .topBar {
    border-radius: 3px;
    padding: 5px 6px;
  }

  .topBar .nav-item.dropdown {
    margin-right: 10px;
  }

  .topBar .navbar-nav a {
    font-size: 13px;
  }
}

.nursing_page_img {
  max-width: 100%;
  height: auto !important;
}

.certificate_txt {
  font-size: 31px !important;
  color: #00416f;
}



.centered-text.certificate_txt {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: #333;
}

.certificate-background {
  position: relative;
  text-align: center;
}

.certificate-background img {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}

.centered-text {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
}

.inprogress-box {
  margin-top: -30px;
  margin-bottom: -30px;
  position: relative;
}

.inprogressTxt {
  position: absolute;
  z-index: 9;
  top: 43%;
  left: 8%;
  font-size: 40px;
  font-weight: 800;
  color: #122a53;
}

.deliveryTimeModel p {
  margin:0px;
  font-size: 18px;
  color: #122a53;
  font-weight: 600;
  text-transform: capitalize;
}

.imagingContactDtls{
  line-height: 30px !important;
}

.modelDeliveryMain {
  top: 35% !important;
}

.nuerology {
  height: 380px !important;
}

.cardiology_text {
  height: 475px;
}

.payment_billing {
  max-width: 650px !important;
}

.pathology_para {
  max-width: 105% !important;
}

.workWithUs h4{
  color: #004470;
  font-size: 1.3rem;
  font-weight: 800;
  margin-bottom: 15px;
}

.workWithUs p{
  color: #004470;
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.workWithUs ul{
  margin: 0;
  padding: 0;
}

.workWithUs ul li {
  color: #004470;
  line-height: 28px;
  list-style-position: inside;
}

.sinisterNote{
  color: red;
  padding: 8px 15px;
  border-radius: 3px;
  background: #fff;
  font-weight: 500 !important;
}

.parkingNote span{
  color: red;
}

.nursing_images{
  float: left;
  max-width: 575px !important;
  margin: 0px 30px 0px 0px;
}

.menu-useful-links {
  padding: 0;
}



@media (min-width: 1000px) and (max-width: 1400px) {
  .contactInfoBox a {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    font-size: 10px;
    text-overflow: ellipsis;
  }

  .pharmacyButton {
    padding: 10px 8px;
    font-size: 12px;
    margin: 5px 2px 10px;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .contactInfoBox a {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    font-size: 10px;
    text-overflow: ellipsis;
  }

  .centered-text {
    text-align: center !important;
  }

  .pharmacyButton {
    padding: 10px 8px;
    font-size: 12px;
    margin: 5px 2px 10px;
  }

  .homeBannerSection .carousel-item img {
    width: 100%;
    height: 650px;
    object-fit: cover;
  }

  .carousel-item .carousel-caption {
    left: 10%;
    padding: 20px;
    margin-top: 10%;
    max-width: 490px;
  }

  .homeAboutTxt{
    margin-bottom: 50px;
  }

}

@media (max-width: 1024px){
  .nutrition_images {
    height: 300px !important;
  }
  .physical_images {
    height: auto !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .column-reverse{
    flex-direction: column-reverse;
  }
  .nursing_images{
    max-width: 100% !important;
    margin-bottom: 30px;
  }
  .nutrition_images {
    height: 460px !important;
  }
  .resonicaThumbImg {
    padding-left: 0 !important;
    margin-bottom: 30px;
  }
  .breadcrumbs h2{
    font-size: 1.2rem;
  }
}

@media (max-width: 768px){

  .innerPageHeader, .innerPageHeader_for_Imaging{
    padding: 0px;
  }

  .homeBannerSection .carousel-item img {
    height: 320px;
    object-fit: cover;
  }

  .homeAboutTxt{
    margin-bottom: 50px;
  }

  .menuCategoryOpt .imgBox h4{
    font-size: 16px;
  }

}

@media (max-width: 768px) {
  .mobileNav {
    display: block;
  }

  .directory_table {
    font-size: 15px;
  }

  .imagingPageBanner {
    min-height: 230px;
  }

  .innerPageHeader_for_Imaging {
    background-color: #122a53;
    padding: 0px
  }

  .imagingStudies h4 {
    font-size: 15px;
    line-height: 24px;
    text-align: justify;
  }

  .allFacality img {
    height: 202px;
    width: 100%;
    object-fit: cover;
  }

  .img_bottom {
    margin-bottom: 0% !important;
  }

  .mt-5 {
    margin-top: 0rem !important;
  }
  
  .nuerology {
    height: 2% !important;
  }

  .menu-useful-links {
    padding: 0;
  }

  .hospitalParkingPageContant {
    font-family: 'Rubik', sans-serif;
    text-align: justify;
  }

  .inprogress_image {
    margin-left: 8%;
  }


  .certificate-background img {
    height: auto;
  }

  .centered-text {
    font-size: 16px;
  }

  .emergencSafety h5 {
    color: #122a53;
    font-weight: 900;
    font-size: 1.3rem;
    line-height: 28px;
    margin-top: 9%;
    font-family: 'Rubik', sans-serif;
  }

  .loadingPage {
    background-color: #00416f;
    width: 100vw;
    height: 10 0vh;
    text-align: center;
    position: relative;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loadingPage img {
    width: 80%;
  }

  .pharmacyButton {
    padding: 10px 8px;
    font-size: 12px;
    margin: 5px 2px 10px;
  }

  .contactInfoBox a {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .carousel-indicators {
    top: 87%;
  }

  .menuCategoryOpt .imgBox img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 15px 15px 0px 0px;
  }

  .menuCategoryOpt {
    margin: 10px 0;
    box-shadow: 0px 0px 5px #cccc;
    border-radius: 10px;
  }

  .menuCategoryOpt .imgBox1 img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 15px 15px 15px 15px !important;
  }

  .by_pass_surgery_image {
    width: 100%;
  }

  .promotionsDiv {
    margin: 5px;
    padding: 10px;
    height: auto;
  }

  .prayer_images {
    width: 100% !important;
  }

  .UltrasoundGallery img {
    height: 300px !important;
    object-position: contain;
  }

  .cardiology_image {
    object-fit: cover;
  }

  .bg {
    margin-top: 0;
    padding-top: 15%;
    margin-bottom: 0;
    padding-bottom: 45%;
  }

  .imagingPageBannerContnt h4 {
    line-height: 28px;
    font-size: 17px;
  }

  .resonica_images {
    height: 415px !important;

  }

  .emergenciPageBannerContnt h4 {
    margin-bottom: 10px;
    font-size: 17px;
  }

  .medicaltowerBigImg {
    height: 610px !important;
    object-fit: cover;
    object-position: bottom;
    width: 100%;
  }

  .emergenciPageBannerContnt {
    max-width: 100%;
    padding: 10px;
  }

  .imageTransparent {
    height: 100%;
    border-radius: 10px !important;
  }

  .paymentIcons {
    width: 25%;
  }

  .paymentIconsforCard {
    width: 40%;
  }

  .certificate_txt {
    font-size: 31px !important;
    margin-top: 17%;
    margin-bottom: 17%;
  }

  .sinisterTxt p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0px;
}

  .paymentIcons {
    width: 100%
  }

  .sinisterTxt ul li {
    font-size: 15px;
    line-height: 24px;
    font-family: 'Rubik', sans-serif;
  }

  .head-top-links,
  .logoBox,
  .topBar {
    display: none;
  }

  .headerSec {
    position: relative;
  }

  .logo {
    max-width: 120px;
  }

  .service-thumb-container {
    margin-top: 50px !important;
    max-width: 280px;
    position: relative;
    margin: auto;
    right: auto;
    top: auto;
  }

  .parkingBigImg {
    width: 100%;
    height: 103% !important;
  }

  .sectionPadding {
    padding: 34px 0px 0px;
  }

  .carousel-item .carousel-caption {
    padding: 15px;
    top: auto;
    left: 6%;
    bottom: 100px;
    max-width: 350px;
    z-index: 99;
    right: auto;
  }

  .carousel-item .carousel-caption h3 {
    font-size: 16px;
  }

  .carousel-item .carousel-caption h4 {
    font-size: 16px;
    margin: 10px 0px 5px;
  }

  .carousel-item .carousel-caption p {
    font-size: 13px !important;
  }

  .sliderBtn {
    padding: 0 10px;
    margin-top: 0px;
    line-height: 30px;
    min-height: 30px;
    min-width: 100px;
    font-size: 11px;
  }

  #footer {
    padding: 30px 0px;
  }

  .footer-widget {
    overflow: hidden;
    clear: both;
    margin-bottom: 30px;
  }

  .copyright-footer {
    margin-top: 0;
    margin-bottom: 0;
  }

  .footer-social a{
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .footer-social a img{
    width: 20px;
  }

}

@media (min-width:320px) and (max-width: 640px) {

  .carousel-item .carousel-caption {
    padding: 13px;
    top: 10%;
    left: 2%;
    bottom: 29px;
    z-index: 99;
    margin: auto;
  }

  .pharmacy-text-read-more {
    text-align: justify;
    font-size: 14px;
    margin-top: 1.5rem !important;
  }

  .centered-text {
    text-align: center !important;
    margin-top: 32%;
  }

  .pharmacyButton {
    padding: 10px 8px;
    font-size: 12px;
    margin: 5px 2px 10px;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: none !important;
  }


  .paymentIcons {
    width: 25%;
  }

  .paymentIconsforCard {
    width: 40%;
  }

  .carousel-item .carousel-caption h3 {
    font-size: 16px;
  }

  .carousel-item .carousel-caption h4 {
    font-size: 14px;
    margin: 5px 0px 5px;
  }

  .carousel-item .carousel-caption p {
    font-size: 12px !important;
  }

  .sliderBtn {
    padding: 0 10px;
    margin-top: 0px;
    line-height: 30px;
    min-height: 30px;
    min-width: 100px;
    font-size: 11px;
  }

  .innerPageHeader {
    padding: 0;
  }

  .sinisterPageBanner {
    padding: 50px 0px;
  }

  .homeAboutTxt p {
    margin-bottom: 30px;
  }

  .fixedIcons a {
    margin-bottom: 10px;
  }

  .fixedIcons a img {
    height: 25px;
  }

  .copyright-footer .d-flex {
    text-align: center;
    flex-direction: column-reverse;
    gap: 10px;
  }

  .incsocialIcons,
  .incContactInfo .whatsApp {
    text-align: center;
  }

  .menuCategoryOpt .imgBox img{
    height: 250px;
  }

  .nursing_images{
    max-width: 100% !important;
    margin-bottom: 30px;
  }

  .breadcrumbs h2{
    font-size: 1.1rem;
  }

  .column-reverse{
    flex-direction: column-reverse;
  }

}